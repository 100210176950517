.channel-preview__container {
  height: 56px;
  border-radius: 8px;
  cursor: url('/images/cursor-pointer.svg'), auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 0.1px solide gray;
  margin-bottom: 10px;
}

.str-chat__channel-list-messenger {
  background: none;
}

.channel-preview__container:hover {
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
}

.str-chat.str-chat__theme-dark .channel-preview__container:hover {
  background: #2c2c30;
}

/* .channel-preview__container-selected {
  background: #ffffff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.07);
  transition: background 0.1s ease-in-out;
} */

.str-chat.str-chat__theme-dark .channel-preview__container.selected {
  background: #2c2c30;
}

.channel-preview__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
}

.channel-preview__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-bottom: 4px;
}

.channel-preview__content-name {
  font-family: Helvetica Neue, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  margin: 0;
  max-width: 158px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.channel-preview__content-name_message {
  margin-left: 7px;
}

/* .channel-preview__container div {
  margin-left: 7px;
  margin-bottom: 14px;
} */

.channel-preview__content-name > div {
  margin-right: 5px;
}

.str-chat.str-chat__theme-dark .channel-preview__content-name {
  color: #ffffff;
}

.channel-preview__content-time {
  font-family: Helvetica Neue, sans-serif;
  font-size: 11px;
  color: #858688;
  margin: 0;
}

.channel-preview__content-message {
  font-family: Helvetica Neue, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  color: #858688;
  height: 16px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.channel-preview__container .str-chat__avatar {
  margin-right: 0;
}

.channel-preview__content {
  display: flex;
}
